<template name="component-name">
  <nav
    class="backdrop-filter backdrop-blur-lg bg-opacity-5 py-4 bg-gray-500 dark:bg-black border-b border-gray-300 dark:border-gray-700 text-gray-800 dark:text-white w-full transition-all duration-300"
  >
    <div
      class="flex flex-wrap justify-between items-end max-w-screen-xl container mx-auto pl-6 pr-6"
    >
      <a href="/" class="flex items-center">
        <img
          src="https://groupware-jds.netlify.app/img/logo.9b511d1b.png"
          class="mr-3 h-6 sm:h-9"
          alt="JDS Logo"
          loading="lazy"
        />
        <span
          ><div>
            <h1
              class="self-center text-xl font-semibold whitespace-nowrap dark:text-green-500"
            >
              Jabar Digital Service
            </h1>
            <p
              class="self-center sm:w-[25px] lg:max-w-sm sm:text-sm lg:text-2xl md:whitespace-nowrap lg:whitespace-nowrap dark:text-green-500"
            >
              Memberikan Dampak untuk Jawa Barat dan Indonesia
            </p>
          </div></span
        >
      </a>
      <!-- <label
        for="default-toggle"
        class="inline-flex relative items-center cursor-pointer"
      >
        <input
          type="checkbox"
          value=""
          id="default-toggle"
          class="sr-only peer"
          @change="onChangeToggle($event)"
          v-model="isDark"
        />
        <div
          class="
            w-11
            h-6
            bg-gray-200
            peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300
            dark:peer-focus:ring-blue-800
            rounded-full
            peer
            dark:bg-green-700
            peer-checked:after:translate-x-full peer-checked:after:border-white
            after:content-['']
            after:absolute
            after:top-[2px]
            after:left-[2px]
            after:bg-white
            after:border-gray-300
            after:border
            after:rounded-full
            after:h-5
            after:w-5
            after:transition-all
            dark:border-gray-600
            peer-checked:bg-blue-600
          "
        ></div>
        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          <MoonIcon v-if="isDark === true" />
          <SunIcon v-else />
        </span>
      </label> -->
    </div>
  </nav>
</template>

<script>
import { SunIcon } from "@vue-hero-icons/outline";
import { MoonIcon } from "@vue-hero-icons/outline";


export default {
  name: "Header",
  components: {
    SunIcon,
    MoonIcon,
  },

  data() {
    return {
      isDark: false,
    };
  },

  methods: {
    selectTheme(indexing) {
      localStorage.setItem("theme", indexing);
      const html = document.querySelector("html");
      html.classList.add(localStorage.getItem("theme"));
      if (indexing == "dark") {
        html.classList.remove("light");
      } else {
        html.classList.remove("dark");
      }
    },

    onChangeToggle(e) {
      let checked = e.target.checked;

      if (checked) {
        this.isDark = true;
        this.selectTheme("dark");
      } else {
        this.isDark = false;
        this.selectTheme("light");
      }

      console.log(checked);
    },
  },
  mounted() {
    document.querySelector("html").classList.add(localStorage.getItem("theme"));
    const localTheme = localStorage.getItem("theme");
    console.log("isDark", this.isDark);

    if (localTheme == "dark") {
      this.isDark = true;
    } else {
      this.isDark = false;
    }
  },
};
</script>
