import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tableList: [
      {
        id: 1,
        name: "Junior Software Programmer - Frontend Developer",
        img: "https://i.pinimg.com/736x/9c/fe/87/9cfe873a69868ee5d6a8b048ed9dbb7f.jpg",
      },
      {
        id: 2,
        name: "Senior Software Programmer - Backend Developer",
      },
      {
        id: 3,
        name: "UX Researcher",
      },
      {
        id: 4,
        name: "Data Analyst",
      },
      {
        id: 5,
        name: "QA Engineer",
      },
    ],
    jobId: "",
  },
  getters: {
    getTableList: (state) => {
      return state.tableList;
    },
    tableData: (state) => (id) => {
      return state.tableList.find((item) => item.id === id);
    },
    theId: (state) => {
      return state.jobId;
    },
  },
  mutations: {
    setJobId: (state, id) => {
      state.jobId = id;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
