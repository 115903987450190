<template name="component-name">
  <div>
    <div class="flex justify-center mt-3 mb-5">
      <div class="flex items-center space-x-1">
        <a
          href="#"
          class="
            flex
            items-center
            px-4
            py-2
            text-gray-500
            bg-gray-300
            dark:bg-black dark:border dark:border-green-500
            rounded-md
          "
        >
          <ArrowCircleLeftIcon class="dark:text-green-500" />
        </a>

        <a
          href="#"
          class="
            px-4
            py-2
            text-gray-700
            bg-gray-200
            w-8
            h-8
            rounded-full
            items-center
            flex
            justify-center
            hover:bg-blue-400
            dark:bg-black dark:text-green-500
            hover:text-white
            dark:border dark:border-green-500
          "
        >
          1
        </a>
        <a
          href="#"
          class="
            px-4
            py-2
            text-gray-700
            bg-gray-200
             w-8
            h-8
            rounded-full
            items-center
            flex
            justify-center
            hover:bg-blue-400 hover:text-white
            dark:bg-black dark:text-green-500 dark:border dark:border-green-500
          "
        >
          2
        </a>
        <a
          href="#"
          class="
            px-4
            py-2
            text-gray-700
            bg-gray-200
            w-8
            h-8
            rounded-full
            items-center
            flex
            justify-center
            hover:bg-blue-400 hover:text-white
            dark:border dark:border-green-500 dark:bg-black dark:text-green-500
          "
        >
          3
        </a>
        <a
          href="#"
          class="
            px-4
            py-2
            text-gray-500
            bg-gray-300
            rounded-md
            hover:bg-blue-400 hover:text-white
            dark:border dark:border-green-500 dark:bg-black dark:text-green-500
          "
        >
          <ArrowCircleRightIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowCircleLeftIcon } from "@vue-hero-icons/outline";
import { ArrowCircleRightIcon } from "@vue-hero-icons/outline";

export default {
  name: "pagination-app",
  components: {
    ArrowCircleLeftIcon,
    ArrowCircleRightIcon,
  },
};
</script>
