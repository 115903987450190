import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import LandingPage from "../views/LandingPage";
import JobDetails from "../views/JobDetails"
import ApplyJobPage from "../views/ApplyJobPage"
import checkEmailReminderPage from "../views/checkEmailReminderPage"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing-page",
    component: LandingPage,
  },
  {
    path: "/job/:id",
    name: "job-details",
  
    component: JobDetails,
    props: true
  },
  {
    path: "/apply-job",
    name: "apply-job",
  
    component: ApplyJobPage,
    props: true
  },
  {
    path: "/check-your-email",
    name: "apply-job",
  
    component: checkEmailReminderPage,
    props: true
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
