
import Header from './Header'
import TableLandingPage from "./TableLandingPage"
import Pagination from "./Pagination"
import Accordion from "./Accordion"



//create global components
export const GlobalComponents = {
    install(Vue) {
        Vue.component('header-app', Header)
        Vue.component('table-app', TableLandingPage)
        Vue.component('pagination-app', Pagination)
        Vue.component('accordion-app', Accordion)
    }
}

