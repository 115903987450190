<template>
  <div>
    <div
      class="bg-gray-100 dark:bg-black transition-all w-full duration-300 min-h-screen"
    >
      <div class="container mx-auto p-4 flex justify-center">
        <div>
          <h6 class="text-xl font-semibold mb-5 mt-5">Job Openings</h6>
          <table-app :tableData="tableList" />
          <div class="mt-5">
            <pagination-app />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EmojiSadIcon } from "@vue-hero-icons/outline";
export default {
  name: "IndexPage",
  components: {
    EmojiSadIcon,
  },

  data() {
    return {};
  },

  computed: {
    tableList() {
      return this.$store.getters.getTableList;
    },
  },
};
</script>
