<template name="component-name">
  <div>
    <div v-for="(data, index) in tableData" :key="index">
      <div class="bg-white lg:w-[832px] sm:w-[100%] mt-2 mb-2">
        <div class="container mx-auto p-3 flex justify-between shadow-lg">
          <h6 class="lg:text-xl sm:text-sm font-semibold name-job">
            {{ data.name }}
          </h6>
          <jds-button @click="goToTheFormPage(data.id)">Apply</jds-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DocumentSearchIcon } from "@vue-hero-icons/outline";
export default {
  name: "table-app",
  components: {
    DocumentSearchIcon,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToTheFormPage(id) {
      this.$router.push({
        name: "job-details",
        params: {
          id: id,
        },
      });

      this.$store.commit("setJobId", id);
    },
  },
};
</script>

<style lang="scss">
.jds-button {
  background-color: gray;
  color: #fff;
  border-radius: 0px;
  min-width: 30%;
  box-shadow: inset 0px 0px 0px 1px #fff;
  max-height: 45px;
}
.name-job {
  max-width: 400px;
}
</style>
