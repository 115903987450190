import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import {GlobalComponents} from "./components/global-components";
import { DesignSystem } from '@jabardigitalservice/jds-design-system'


Vue.config.productionTip = false;

GlobalComponents.install(Vue);
Vue.use(DesignSystem)
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
