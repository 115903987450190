<template name="component-name">
  <div
    class="bg-gray-100 dark:bg-black transition-all w-full duration-300 min-h-screen"
  >
    <div class="mx-auto p-4 w-full">
      <div class="grid grid-cols-12 gap-1 md:divide-x-0 sm:divide-x-0">
            
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-4 2xl:col-span-4"
        >
          <div class="flex justify-center items-center"></div>
        </div>
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7"
        >
          <div class="container mx-auto">
            <div class="flex justify-start my-8 pl-6">
              <h1 class="text-4xl semi-bold dark:text-green-500">
                {{ tableData.name }}
              </h1>
            </div>
            <div class="container mx-auto p-4 border border-gray-500 w-11/12">
              <h4 class="font-semibold dark:text-white">
                Submit Your Application
              </h4>
              <div
                class="container p-4 mt-4 border bg-red-300 border-red-300 w-full dark:bg-black dark:border-green-500"
              >
                <h5 class="text-black dark:text-green-500">
                  <span class="font-semibold mr-2 text-red-600"
                    >Perhatikan.</span
                  >Pastikan nomor telepon dan alamat email bisa dihubungi
                </h5>
              </div>
              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label
                    id="fullName"
                    class="text-gray-700 dark:text-green-500"
                  >
                    Full Name <span class="mr-1 text-red-500">*</span>:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="fullName"
                    type="text"
                    placeholder="Full Name"
                    v-model="fullName"
                  />
                  <small class="text-red-500">{{ errMsg.fullName }}</small>
                </div>
              </div>

              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label id="email" class="text-gray-700 dark:text-green-500"
                    >Email <span class="mr-1 text-red-500">*</span>:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    @change="validateEmail"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Email"
                    v-model="email"
                  />
                  <small class="text-red-500">{{ errMsg.email }}</small>
                </div>
              </div>

              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label id="email" class="text-gray-700 dark:text-green-500"
                    >Nomor Telepon
                    <span class="mr-1 text-red-500">*</span>:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="number"
                    placeholder="Nomor Telepon"
                    v-model="phone"
                    @input="onChangePhoneNumber($event)"
                  />
                  <small class="text-red-500">{{ errMsg.phone }}</small>
                </div>
              </div>

              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label id="email" class="text-gray-700 dark:text-green-500"
                    >Resume/CV <span class="mr-1 text-red-500">*</span>:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    @change="pickFileResume"
                    ref="resume"
                    accept="application/pdf"
                    :style="showFileName ? '' : 'color: transparent;'"
                    type="file"
                    class="text-sm text-grey-500 file:mr-5 file:py-2 file:px-6 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-[#069550] file:text-white hover:file:cursor-pointer hover:file:bg-green-600 hover:file:text-white"
                  />

                  <div>
                    <small class="text-red-500 mt-5">{{
                      errMsg.fileResume
                    }}</small>
                  </div>
                </div>
              </div>

              <h6 class="font-semibold mt-4 dark:text-white">Links</h6>

              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label id="fullName" class="text-gray-700 dark:text-green-500"
                    >Linkedin URL<span class="mr-1 text-red-500">*</span
                    >:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="fullName"
                    type="text"
                    v-model="linkedin"
                  />
                  <small class="text-red-500">{{ errMsg.linkedin }}</small>
                </div>
              </div>

              <div class="grid grid-cols-12 gap-1 mt-4">
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 2xl:col-span-3"
                >
                  <label
                    id="portofolio"
                    class="text-gray-700 dark:text-green-500"
                    >Portofolio URL<span class="mr-1 text-red-500">*</span
                    >:</label
                  >
                </div>
                <div
                  class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 2xl:col-span-9"
                >
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="portofolio"
                    type="text"
                    v-model="portofolio"
                  />
                  <small class="text-red-500">{{ errMsg.portofolio }}</small>
                </div>
              </div>

              <h6 class="font-semibold mt-4 dark:text-white">
                Additional Information
              </h6>

              <textarea
                class="mt-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-full"
                id="portofolio"
                type="text"
                rows="4"
                v-model="additionalInfo"
              ></textarea>
              <div>
                <div class="mt-4">
                  <jds-button @click="onSubmit">Submit</jds-button>
                  <jds-button @click="goToThePrevPage">Back </jds-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      fullName: "",
      email: "",
      phone: "",
      linkedin: "",
      portofolio: "",
      fileResume: null,
      showFileName: false,
      additionalInfo: "",
      errMsg: {
        fullName: "",
        email: "",
        phone: "",
        linkedin: "",
        portofolio: "",
        fileResume: "",
      },
    };
  },

  computed: {
    tableData() {
      return this.$store.getters.tableData(parseInt(this.$store.getters.theId));
    },
  },

  methods: {
    onChangePhoneNumber(e) {
      this.phone = e.target.value;

      if (this.phone.length > 14) {
        this.phone = this.phone.substring(0, 14);
      }
    },
    pickFileResume(e) {
      let input = this.$refs.resume;
      let file = input.files;
      if (file && file[0] && file[0].size < 5000000) {
        this.showFileName = true;
        this.fileResume = input.files[0];
      } else {
        this.showFileName = false;
        this.errMsg.fileResume = "File size must be less than 5MB";
      }
    },

    validateEmail() {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        this.errMsg.email = "";
        console.log("valid email");
        return true;
      } else {
        this.errMsg.email = "Email is not valid";
        console.log("invalid email");
        return false;
      }
    },
    onSubmit() {
      if (this.fullName == "") {
        this.errMsg.fullName = "Name is required";
      }
      if (this.email == "") {
        this.errMsg.email = "Email is required";
      } else if (this.validateEmail() == false) {
        this.errMsg.email = "Email is not valid";
      }
      if (this.phone == "") {
        this.errMsg.phone = "Phone is required";
      }
      if (this.fileResume == null) {
        this.errMsg.fileResume = "File is required";
      }
      if (this.linkedin == "") {
        this.errMsg.linkedin = "Linkedin URL is required";
      }
      if (this.portofolio == "") {
        this.errMsg.portofolio = "Portofolio URL is required";
      }

      if (
        this.fullName != "" &&
        this.email != "" &&
        this.phone != "" &&
        this.linkedin != "" &&
        this.portofolio != "" &&
        this.fileResume != null
      ) {
        const payload = {
          id: this.$store.getters.theId,
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          linkedin: this.linkedin,
          portofolio: this.portofolio,
          fileResume: this.fileResume,
          additionalInfo: this.additionalInfo,
        };
        console.log("payload", payload);
        this.$router.push("/check-your-email");
      }
    },
    goToThePrevPage() {
      this.$router.go(-1);
    },
  },
};
</script>
