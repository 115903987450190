<template name="component-name">
  <div class="transition-all w-full duration-300 min-h-screen">
    <div class="mx-auto p-4 w-full">
      <div class="grid grid-cols-12 gap-1 md:divide-x-0 sm:divide-x-0">
            
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-4 2xl:col-span-4"
        >
          <div class="flex justify-center items-center h-screen">
            <div
              class="container mx-auto pr-3 pt-3 pb-[500px] bg-gray-300"
            ></div>
          </div>
        </div>
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 xl:col-span-8 2xl:col-span-8"
        >
          <div class="container mx-auto p-6">
            <div class="flex justify-start my-8">
              <h1 class="text-4xl semi-bold dark:text-green-500">
                {{ tableData.name }}
              </h1>
            </div>
            <div class="flex justify-start">
              <div>
                <h6 class="font-semibold">About The Role</h6>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Temporibus ipsa quisquam delectus, iusto, iure expedita libero
                  deleniti impedit eaque beatae nisi odit possimus facere harum
                  veniam deserunt. Omnis, architecto doloremque! Lorem ipsum,
                  dolor sit amet consectetur adipisicing elit. Temporibus ipsa
                  quisquam delectus, iusto, iure expedita libero deleniti
                  impedit eaque beatae nisi odit possimus facere harum veniam
                  deserunt. Omnis, architecto doloremque!
                </p>

                <h6 class="font-semibold">What will You Do</h6>
                <div class="container mx-auto p-4">
                  <ul>
                    <li>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li>
                      Temporibus ipsa quisquam delectus, iusto, iure expedita
                      libero
                    </li>
                    <li>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li>
                      Temporibus ipsa quisquam delectus, iusto, iure expedita
                    </li>
                  </ul>
                </div>

                <h6 class="font-semibold">What will You Need</h6>
                <div class="container mx-auto p-4">
                  <ul>
                    <li>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li>
                      Temporibus ipsa quisquam delectus, iusto, iure expedita
                      libero
                    </li>
                    <li>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    </li>
                    <li>
                      Temporibus ipsa quisquam delectus, iusto, iure expedita
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-start mt-5 container mx-auto p-6">
            <jds-button @click="goToTheApplyJobPage">Apply Now</jds-button>
            <jds-button @click="getBackToLandingPage">Back to List</jds-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "job-details",
  data() {
    return {
      name: "",
      img: "",
    };
  },
  methods: {
    getBackToLandingPage() {
      this.$router.push("/");
    },
    goToTheApplyJobPage() {
      this.$router.push({
        name: "apply-job",
      });
      // this.$router.push(`/apply-job/${payload}`)
    },
  },
  computed: {
    tableData() {
      return this.$store.getters.tableData(parseInt(this.$route.params.id));
    },
  },
};
</script>

<style lang="scss">
.jds-button {
  background-color: gray;
  color: #fff;
  border-radius: 0px;
  min-width: 30%;
  box-shadow: inset 0px 0px 0px 1px #fff;
  max-height: 45px;
}
</style>
