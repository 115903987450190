<template name="component-name">
  <div
    class="bg-gray-100 dark:bg-black transition-all w-full duration-300 min-h-screen"
  >
    <div class="mx-auto p-4 w-full">
      <div class="grid grid-cols-12 gap-1 md:divide-x-0 sm:divide-x-0">
            
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-4 2xl:col-span-4"
        >
          <div class="flex justify-center items-center"></div>
        </div>
        <div
          class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7"
        >
          <div class="container mx-auto">
            <div class="flex justify-start my-8">
              <h1 class="text-4xl semi-bold dark:text-green-500">
                {{ tableData.name }}
              </h1>
            </div>
            <div class="w-11/12">
              <div
                class="container p-4 mt-4 border bg-gray-300 border-gray-300 w-full dark:bg-black dark:border-green-500"
              >
                <h5 class="text-black font-semibold">
                  Please check your email
                </h5>
                <h5 class="text-black dark:text-green-500">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Optio molestiae fuga minima! Soluta impedit hic amet eaque
                  cumque labore maxime fugiat numquam pariatur dolore, debitis
                  id autem sequi minus similique.
                </h5>
              </div>

              <div>
                <div class="mt-4">
                  <jds-button @click="backToTheHome">Back to Home</jds-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tableData() {
      return this.$store.getters.tableData(parseInt(this.$store.getters.theId));
    },
  },
  methods:{
    backToTheHome(){
      this.$router.push("/");
    },
  }
};
</script>
